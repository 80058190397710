import './App.css';
import Header from "./MyComponent/header";
import Codeo from "./MyComponent/codeo";
import Footer from "./MyComponent/footer";



function App() {
  return (
    <>
  <Header/>
    
        <Codeo/>
        <Footer/>
        
    
    </>

  );
}

export default App;
